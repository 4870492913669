//Custom

.cursor-pointer {
  cursor: pointer;
}

// adds a divider between select options
select > optgroup > .divider {
  font-size: 1px;
  background: rgba(0, 0, 0, 0.5);
}

//disable card

.card-disable {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  background-color: lightgrey !important;
  opacity: 0.5;
  cursor: auto !important;
}

.border-left-danger {
  border-left: 5px solid #dc3545 !important;
}

// if text is too long, we can truncate
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// toast notifications container
// sticky-top float-right pt-3 pr-3
.toast-container {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 1000;
}

// text overflow add ellipsis
.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


// Form Validation
.form-control.ng-invalid.ng-touched {
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}


// Fhir Resource Cards

.medical-record-wizard {
  .card {
      margin-top: 10px;
  }
}

.card-fhir-resource-popover {
  max-width:500px;
}

.card-fhir-resource {
  position: relative;
  border-color: $gray-300;

  .card-header {
    padding: 20px;
    background-color: transparent;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .card-title {
      color: $gray-900;
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
      margin-bottom: 3px;
    }

    .card-text {
      margin-bottom: 0;
    }

    .btn-group {
      margin-top: 15px;
      @include media-breakpoint-up(sm) { margin-top: 0; }

      .btn {
        font-size: 12px;
        font-weight: 500;
        padding: 5px 10px;
        min-height: inherit;
        background-color: #fff;
        color: $gray-600;
        border: 1px solid $gray-300;

        @include hover-focus() {
          background-color: $gray-100;
          border-color: $gray-300;
        }

        + .btn { margin-left: -1px; }

        &.active {
          color: $gray-900;
          background-color: $gray-200;
          border-color: $gray-300;
        }
      }
    }
  }

  //.card-body {
  //  padding: 10px 0 20px;
  //  position: relative;
  //  overflow: hidden;
  //
  //  .flot-chart-wrapper {
  //    position: relative;
  //    margin-left: -28px;
  //    margin-right: -20px;
  //  }
  //
  //  .flot-chart {
  //    width: 100%;
  //    height: 150px;
  //
  //    @include media-breakpoint-up(sm) { height: 275px; }
  //    @include media-breakpoint-only(lg) { height: 252px; }
  //
  //    .flot-y-axis > div {
  //      transform: translateX(50px);
  //      text-shadow: 1px 1px rgba(#fff, .75);
  //      color: $body-color;
  //      font-weight: 700;
  //      font-size: 11px;
  //    }
  //
  //    .flot-x-axis > div {
  //      color: $gray-500;
  //      font-weight: 500;
  //      font-size: 11px;
  //    }
  //  }
  //}

  .card-body-top {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 20px;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: -5px;
      left: 20px;
      z-index: 5;
      padding-left: 0;
    }

    > div {
      flex-basis: 50%;
      @include media-breakpoint-up(sm) { flex-basis: auto; }

      + div {
        @include media-breakpoint-up(sm) { margin-left: 30px; }
      }
    }

    label {
      font-size: 12px;
      margin-bottom: 3px;

      @include media-breakpoint-up(sm) { font-size: $font-size-base; }
    }

    h2 {
      font-size: 20px; //$font-size-base;
      font-weight: 600;
      font-family: 'Archivo', $font-family-base;
      color: $gray-900;

      @include media-breakpoint-up(sm) {
        font-size: 24px;
        letter-spacing: -.5px;
      }
    }
  }
}


// ng-select/select2 styles

ng-select.ng-select-form-control {
  .ng-select-container {
    border-radius: 0px;
    height: 38px;
  }
}

//ngTypeAhead
app-nlm-typeahead {
  .dropdown-menu.show {
    max-height: 350px;
    overflow-y: scroll;
  }
}

//Gridster drag-and-drop dashboard
.grid-stack-item.ui-draggable.ui-resizable {
  //this item is in "editable mode"
  .grid-stack-item-content {
    border: 2px dashed grey;
    padding:5px;
    border-radius: 10px;
  }
}

//Medical Source Page
.modal-header-media-image {
  max-height: 50px;
  max-width: 100px;
}


//Medical Source Filter
.category-label {
  font-weight: 400
}

app-medical-sources-filter > .az-content-left-components {
  overflow-x: hidden;
  -webkit-transition: width 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}
app-medical-sources-filter > .az-content-left-components:hover{
  width: 100%;
  -webkit-transition: width 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}

//Fhir Datadable
.datatable-body .datatable-body-row.active {
  background-color: $indigo !important;
  color: #fff;
}
.fhir-resource-datatable .datatable-body-row {
  cursor: pointer;
}

// Callouts


.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;
}

.bd-callout h4 {
  margin-top: 0;
  margin-bottom: .25rem;
}

.bd-callout p:last-child {
  margin-bottom: 0;
}

.bd-callout code {
  border-radius: .25rem;
}

.bd-callout + .bd-callout {
  margin-top: -.25rem;
}

// Variations
@mixin bs-callout-variant($color) {
  border-left-color: $color;
  border-left-width: .25rem;
  h4 { color: $color; }
}

.fhir-card-callout-success .card, .bd-callout-success { @include bs-callout-variant($green); }
.fhir-card-callout-info .card, .bd-callout-info { @include bs-callout-variant($cyan); }
.fhir-card-callout-warning .card, .bd-callout-warning { @include bs-callout-variant($yellow); }
.fhir-card-callout-danger .card, .bd-callout-danger { @include bs-callout-variant($red); }


// lhc-autocomplete fixes - if we dont set this z-index, the autocomplete results are hidden behind the modal
#searchResults {
  z-index: 9999 !important;
}

// LHC Forms
.lhc-form-title {
  border-radius: inherit;
  background-color: $indigo;
}

.lhc-item.lhc-tree-line::before {
  border-top: 2px solid $indigo;
}

.lhc-item.lhc-tree-line::after {
  border-left: 2px solid $indigo;
}

.lhc-item-question.lhc-active-row {
  background-color: $primary;
}
