/* ###### 4.8 Nav   ###### */
@media (min-width: 768px) {
  .az-nav {
    align-items: center;
  }
}
.az-nav .nav-link {
  display: block;
  color: #596882;
  padding: 0;
  position: relative;
  line-height: normal;
}
.az-nav .nav-link:hover-focus() {
  color: #1c273c;
}
.az-nav .nav-link + .nav-link {
  padding-top: 12px;
  margin-top: 12px;
  border-top: 1px dotted #97a3b9;
}
@media (min-width: 768px) {
  .az-nav .nav-link + .nav-link {
    padding-top: 0;
    margin-top: 0;
    border-top: 0;
    padding-left: 15px;
    margin-left: 15px;
    border-left: 1px dotted #97a3b9;
  }
}
.az-nav .nav-link.active {
  color: #5b47fb;
}

.az-nav-column {
  flex-direction: column;
}
.az-nav-column .nav-link {
  padding: 0;
  height: 38px;
  color: #1c273c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.az-nav-column .nav-link i {
  font-size: 24px;
  line-height: 0;
  width: 24px;
  margin-right: 12px;
  text-align: center;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .az-nav-column .nav-link i {
    transition: none;
  }
}
.az-nav-column .nav-link i:not([class*=" tx-"]) {
  color: #7987a1;
}
.az-nav-column .nav-link i.typcn {
  line-height: 0.9;
}
.az-nav-column .nav-link span {
  font-weight: 400;
  font-size: 11px;
  color: #97a3b9;
  margin-left: auto;
}
.az-nav-column .nav-link:hover, .az-nav-column .nav-link:focus {
  color: #1c273c;
}
.az-nav-column .nav-link:hover i:not([class*=" tx-"]), .az-nav-column .nav-link:focus i:not([class*=" tx-"]) {
  color: #1c273c;
}
.az-nav-column .nav-link.active {
  position: relative;
}
.az-nav-column .nav-link.active::before {
  content: "";
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: -28px;
  width: 3px;
  background-color: #5b47fb;
  border-radius: 3px;
  display: none;
}
.az-nav-column .nav-link.active, .az-nav-column .nav-link.active:hover, .az-nav-column .nav-link.active:focus {
  color: #5b47fb;
}
.az-nav-column .nav-link.active i, .az-nav-column .nav-link.active:hover i, .az-nav-column .nav-link.active:focus i {
  color: #5b47fb;
}
.az-nav-column .nav-link + .nav-link {
  border-top: 1px dotted #b4bdce;
}
.az-nav-column.sm .nav-link {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 10px 0;
}
.az-nav-column.sm .nav-link i {
  font-size: 21px;
}

.az-nav-dark .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.az-nav-dark .nav-link:hover-focus() {
  color: #fff;
}
.az-nav-dark .nav-link + .nav-link {
  border-color: #596882;
}
.az-nav-dark .nav-link.active {
  color: #5b47fb;
}

.az-nav-colored-bg .nav-link + .nav-link {
  border-color: rgba(255, 255, 255, 0.4);
}
.az-nav-colored-bg .nav-link.active {
  color: #fff;
}

.az-nav-line {
  position: relative;
}
.az-nav-line .nav-link {
  padding: 0;
  color: #596882;
  position: relative;
}
.az-nav-line .nav-link:hover-focus() {
  color: #1c273c;
}
.az-nav-line .nav-link + .nav-link {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .az-nav-line .nav-link + .nav-link {
    margin-top: 0;
    margin-left: 30px;
  }
}
.az-nav-line .nav-link.active {
  color: #1c273c;
}
.az-nav-line .nav-link.active::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -20px;
  width: 2px;
  background-color: #1c273c;
}
@media (min-width: 768px) {
  .az-nav-line .nav-link.active::before {
    top: auto;
    bottom: -20px;
    left: 0;
    right: 0;
    height: 2px;
    width: auto;
  }
}
.az-nav-line.az-nav-dark .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.az-nav-line.az-nav-dark .nav-link:hover-focus() {
  color: #fff;
}
.az-nav-line.az-nav-dark .nav-link.active {
  color: #fff;
}
.az-nav-line.az-nav-dark .nav-link.active::before {
  background-color: #fff;
}

.az-nav-tabs {
  padding: 15px 15px 0;
  background-color: #cdd4e0;
}
.az-nav-tabs .lSSlideOuter {
  position: relative;
  padding-left: 32px;
  padding-right: 35px;
}
.az-nav-tabs .lSSlideWrapper {
  overflow: visible;
}
.az-nav-tabs .lSAction > a {
  display: block;
  height: 40px;
  top: 16px;
  opacity: 1;
  background-color: #b4bdce;
  background-image: none;
}
.az-nav-tabs .lSAction > a:hover-focus() {
  background-color: #a5afc4;
}
.az-nav-tabs .lSAction > a::before {
  font-family: "Ionicons";
  font-size: 18px;
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.az-nav-tabs .lSAction > a.lSPrev {
  left: -32px;
}
.az-nav-tabs .lSAction > a.lSPrev::before {
  content: "\f3cf";
}
.az-nav-tabs .lSAction > a.lSNext {
  right: -35px;
}
.az-nav-tabs .lSAction > a.lSNext::before {
  content: "\f3d1";
}
.az-nav-tabs .lSAction > a.disabled {
  background-color: #e3e7ed;
  color: #fff;
}
.az-nav-tabs .lightSlider {
  display: flex;
}
.az-nav-tabs .tab-item {
  flex-shrink: 0;
  display: block;
  float: none;
  min-width: 150px;
}
.az-nav-tabs .tab-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  line-height: 1.428;
  color: #596882;
  white-space: nowrap;
  background-color: #e3e7ed;
}
.az-nav-tabs .tab-link:hover-focus() {
  background-color: #f4f5f8;
}
.az-nav-tabs .tab-link.active {
  background-color: #fff;
  color: #1c273c;
  font-weight: 500;
}

.az-tab-pane {
  display: none;
}
.az-tab-pane.active {
  display: block;
}
